.Acontainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .Aheading {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .Aimage {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .Atext {
    font-size: 18px;
    line-height: 1.5;
    text-align: justify;
    margin-bottom: 20px;
  }
  